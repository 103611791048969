import { CardBanner } from './CardBanner';
import * as childs from './childs';

export type TypeCardBanner = typeof CardBanner & {
  Pretitle: typeof childs.Pretitle;
  Title: typeof childs.Title;
  Text: typeof childs.Text;
  Button: typeof childs.Button;
  IconButton: typeof childs.IconButton;
};

(CardBanner as TypeCardBanner).Pretitle = childs.Pretitle;
(CardBanner as TypeCardBanner).Title = childs.Title;
(CardBanner as TypeCardBanner).Text = childs.Text;
(CardBanner as TypeCardBanner).Button = childs.Button;
(CardBanner as TypeCardBanner).IconButton = childs.IconButton;

childs.Pretitle.displayName = 'CardBanner.Pretitle';
childs.Title.displayName = 'CardBanner.Title';
childs.Text.displayName = 'CardBanner.Text';
childs.Button.displayName = 'CardBanner.Button';
childs.IconButton.displayName = 'CardBanner.IconButton';

export type { TypeCardBannerProps } from './types';
export type { TypeButtonProps, TypeTextChildProps } from './childs/types';

export default CardBanner as TypeCardBanner;
