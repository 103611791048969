import type { FunctionComponent } from 'react';
import type { TypeTextChildProps } from './types';

export const Title: FunctionComponent<TypeTextChildProps> = ({
  className = '',
  color,
  children,
}: TypeTextChildProps): JSX.Element => (
  <div
    className={`u-headline u-headline@mobile--h2 ${className}`}
    style={{ color: color }}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);
