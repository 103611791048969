import { CardBanner } from '@/molecules';

import { containerTransformer, iconTransformer } from '../common';

import type { TypeCardBannerGraphQL } from '@/lib/graphql/types';
import type { TypeCardBannerProps } from '@/molecules/card-banner';

export const cardBannerTransformer = (
  card: TypeCardBannerGraphQL,
): TypeCardBannerProps => {
  const container = containerTransformer(card);
  const children = card.content.map((item: any, index: number) => {
    let Component = null;

    switch (item.fieldGroupName) {
      case 'Banner_CptBanner_Content_Pretitle':
        Component = (
          <CardBanner.Pretitle
            {...(item?.color ? { color: item.color } : {})}
            key={index}
          >
            {item.text}
          </CardBanner.Pretitle>
        );
        break;
      case 'Banner_CptBanner_Content_Title':
        Component = (
          <CardBanner.Title
            {...(item?.color ? { color: item.color } : {})}
            {...(item?.size ? { className: item.size } : {})}
            key={index}
          >
            {item.text}
          </CardBanner.Title>
        );
        break;
      case 'Banner_CptBanner_Content_Button':
        Component =
          item?.link?.url && item?.label ? (
            <CardBanner.Button
              variant={item.style}
              href={item.link.url}
              negative={item.negativo}
              size={item.size}
              key={index}
            >
              {item.label}
            </CardBanner.Button>
          ) : null;
        break;
      case 'Banner_CptBanner_Content_Text':
        Component = (
          <CardBanner.Text
            {...(item?.color ? { color: item.color } : {})}
            key={index}
          >
            {item.text}
          </CardBanner.Text>
        );
        break;
      case 'Banner_CptBanner_Content_ButtonIcon':
        const Icon = iconTransformer(item.icon);

        Component = item?.icon && item?.linkText && (
          <CardBanner.IconButton
            {...(item?.size ? { size: item.size } : {})}
            {...(item?.style ? { style: item.style } : {})}
            href={item.linkText}
            key={index}
          >
            <Icon />
          </CardBanner.IconButton>
        );
    }

    return Component;
  });

  return {
    ...container,
    ...{
      style: {
        ...container.style,
        minHeight: `${card.minHeight}px`,
      },
    },
    padding: card.double ? 'p-3 xs:p-8' : 'p-3',
    ...(card?.imageLink &&
      card.imageLink?.url && {
        backgroundImageLink: card.imageLink,
      }),
    children,
  };
};
