import { getRealURL, priceToNumber } from '@/lib/utils';

import type { productCardWordpressTransformer } from '@/lib/graphql/transformers/card-product/woordpress';
import type { TypeGTMProduct } from '@/types';

const router = () => new URLSearchParams(window?.location?.search);

export const normalizeItemServer = (
  data: NonNullable<ReturnType<typeof productCardWordpressTransformer>>,
  id?: string,
  name?: string,
  index?: number,
): TypeGTMProduct => ({
  item_id: data?.sku ?? '',
  item_name: data?.name ?? '',
  // TODO: cuando se implmente el buy box esto no servirá porque puede haber varios sellers
  affiliation: data?.affiliation,
  coupon: null,
  currency: 'EUR',
  discount: data?.discountAmount ?? null,
  index: index ?? null,
  item_brand: data?.brand ?? null,
  item_variant: null,
  price: priceToNumber(data?.regularPrice ?? ''),
  quantity: 1,
  item_list_id:
    id ?? (router().get('itemListId') as string | undefined) ?? null,
  item_list_name:
    name ?? (router().get('itemListName') as string | undefined) ?? null,
  image: data?.image?.src ?? null,
  url: getRealURL(data?.uri),
  categories: data?.categories,
});
