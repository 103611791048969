import { NextLink } from '@/atoms';
import { IconButton as IconButtonMolecule } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeIconButtonProps } from './types';

/**
 * IconButton
 */
export const IconButton: FunctionComponent<TypeIconButtonProps> = ({
  className,
  children,
  href,
  style,
  size,
}: TypeIconButtonProps) => (
  <NextLink href={href} passHref>
    <IconButtonMolecule
      as="a"
      style={style}
      size={size}
      {...(className && { className })}
    >
      {children}
    </IconButtonMolecule>
  </NextLink>
);

IconButton.displayName = 'IconButton';
