import type { FunctionComponent } from 'react';
import type { TypeTextChildProps } from './types';

export const Text: FunctionComponent<TypeTextChildProps> = ({
  className = '',
  color,
  children,
}: TypeTextChildProps): JSX.Element => (
  <div
    className={`u-body u-body--m ${className}`}
    style={{ color: color }}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);
