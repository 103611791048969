import { NextLink } from '@/atoms';
import { Button as ButtonMolecule } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeButtonProps } from './types';

export const Button: FunctionComponent<TypeButtonProps> = ({
  className,
  variant = 'tertiary',
  disabled = false,
  size = 'normal',
  negative = false,
  href,
  ...props
}: TypeButtonProps): JSX.Element => (
  <NextLink href={href} passHref>
    <ButtonMolecule
      as="a"
      {...(className && { className })}
      variant={variant}
      disabled={disabled}
      size={size}
      negative={negative}
      {...props}
    />
  </NextLink>
);
