import { Container } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeCardBannerProps } from './types';

/**
 * CardBanner
 */
export const CardBanner: FunctionComponent<TypeCardBannerProps> = ({
  className = '',
  padding = 'p-4',
  align,
  justify,
  textAlign,
  backgroundColor,
  backgroundImage,
  backgroundImageLink,
  notLazy,
  overlay,
  style,
  children,
}: TypeCardBannerProps) => (
  <Container
    padding={padding}
    className={`h-full outline outline-1 outline-primary-200 ${className}`}
    classNameWrapper="gap-2"
    align={align}
    justify={justify}
    textAlign={textAlign}
    backgroundImage={backgroundImage}
    notLazy={notLazy}
    overlay={overlay}
    backgroundColor={backgroundColor}
    {...(backgroundImageLink?.url && {
      backgroundImageLink: {
        url: backgroundImageLink.url,
        target: backgroundImageLink.target,
      },
    })}
    style={style}
    wrapper
  >
    {children}
  </Container>
);

CardBanner.displayName = 'CardBanner';
